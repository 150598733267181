import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import debounce from 'lodash/debounce';

import './carousel.css';
import CarouselNav from './carousel-nav/CarouselNav';
import CarouselItems from './carousel-items/CarouselItems';
import CarouselArrowContainer from './carousel-arrow/CarouselArrowContainer';

class Carousel extends React.Component {
  carousel = React.createRef();

  componentDidUpdate() {
    this.carousel.current.focus();
  }

  onKeyPress = debounce((e) => {
    const { activeRow, categories } = this.props.carousel;

    if (e.keyCode === 38) {
      this.onRowChange(Math.max(0, activeRow - 1));
    }

    if (e.keyCode === 40) {
      this.onRowChange(Math.min(categories.length - 1, activeRow + 1));
    }
  }, 300, { leading: true, trailing: false })

  onRowChange = i => {
    if (i !== this.props.activeRow) {
      this.props.setActiveRow(i);
    }
  };

  render() {
    const { carousel, header, onItemClick } = this.props;
    const offsetY = 100 * carousel.activeRow;

    const style = classNames({
      carousel__container: true,
      'carousel__container--visible': header.scrolled
    });

    return (
      <div className="carousel" tabIndex="0" ref={this.carousel} onKeyDown={this.onKeyPress}>
        <CarouselNav
          activeRow={carousel.activeRow}
          items={carousel.categories}
          onClick={this.onRowChange}
        />
        <div className={style}>
          <div
            className="carousel__rows"
            style={{ transform: `translate3d(0, -${offsetY}%, 0)` }}
          >
            {carousel.categories.map((row, i) => (
              <div className="carousel__row" key={i}>
                <CarouselItems
                  category={row}
                  onItemClick={onItemClick}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

Carousel.propTypes = {
  onItemClick: PropTypes.func.isRequired,
  setActiveRow: PropTypes.func.isRequired,
};

export default Carousel;
