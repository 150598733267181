import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './carousel-arrow.css';
import { DIR_LEFT } from '../../../utils/constants';

class CarouselArrow extends Component {
  render() {
    const rotate = this.props.direction === DIR_LEFT ? 180 : 0;

    return (
      <span className="carousel-arrow">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="-905.9 936.6 75.8 19"
          style={{
            filter: 'drop-shadow(rgba(0, 0, 0, 0.8) 0px 0px 2px)',
            transform: `rotate(${rotate}deg)`
          }}
        >
          <path
            d="M-904.9,947.1h71.4l-6.8,6.8c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l8.5-8.5c0.4-0.4,0.4-1,0-1.4
          l-8.5-8.5c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l6.8,6.8h-71.4c-0.6,0-1,0.4-1,1C-905.9,946.7-905.5,947.1-904.9,947.1z"
          />
        </svg>
      </span>
    );
  }
}

CarouselArrow.propTypes = {
  direction: PropTypes.string.isRequired
};

export default CarouselArrow;
