import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import './carousel-items.css';
import CarouselItem from './carousel-item/CarouselItem';
import { DIR_LEFT } from '../../../utils/constants';
import { setActiveItem } from '../actions';

class CarouselItems extends React.PureComponent {
  onNavigate = (e, dir) => {
    e.stopPropagation();

    const activeItem = (dir === DIR_LEFT)
      ? Math.max(0, this.props.category.activeItem - 1)
      : Math.min(this.props.category.activeItem + 1, this.props.category.projects.length - 1);

    this.props.setActiveItem(activeItem, this.props.category.id);
  }

  render() {
    const { category, onItemClick } = this.props;
    const { projects } = category;
    //inverts scroll logic
    const x = -category.activeItem * 150;

    return (
      <div className="carousel__items" style={{ transform: `translate3d(${x}%, 0, 0)` }}>
        {projects.map((item, i) => (
          <CarouselItem
            category={category}
            id={i}
            isFirst={i === 0}
            isLast={i === projects.length - 1}
            item={item} key={item.id}
            onItemClick={onItemClick}
            onNavigate={this.onNavigate} />
        ))}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  carousel: state.carousel,
  ...ownProps
})

const mapDispatchToProps = dispatch => {
  return {
    setActiveItem: (id, cat) => {
      dispatch(setActiveItem(id, cat));
    }
  }
}

CarouselItems.propTypes = {
  category: PropTypes.object.isRequired,
  onItemClick: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(CarouselItems);