import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { withRouter } from 'react-router';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import './app.css';
import HomeContainer from './components/home/HomeContainer';
import NavigationContainer from './components/navigation/NavigationContainer';
import About from './components/about/About';
import ModalProject from './components/modal-project/ModalProject';
import ProjectContainer from './components/project/ProjectContainer';
import Modernitska from './components/modernitska/Modernitska';

class App extends Component {
  render() {
    const { location } = this.props;
    const isModal = location.state && location.state.to === 'modal';
    const previousView = isModal && location.state.from;

    return (
      <div className="app">
        <NavigationContainer />

        <TransitionGroup component={null}>
          <CSSTransition
            key={location.key}
            timeout={{ enter: 350, exit: 350 }}
            classNames="fade">
            <Switch location={isModal ? previousView : location}>
              <Route exact path="/" component={HomeContainer} />
              <Route path="/about" component={About} />
              <Route path="/modernitska" component={Modernitska} />
              <Route path="/:slug" component={ProjectContainer} />
            </Switch>
          </CSSTransition>
        </TransitionGroup>

        <ModalProject isModal={isModal} />
      </div>
    );
  }
}

export default withRouter(App);
