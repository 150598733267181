import { connect } from 'react-redux';

import Home from './Home';
import { toggleHeader } from '../home-header/actions';
import { updateMousePosition } from './actions';
import { updateNavColor } from '../navigation/actions';
import { addCategories } from '../carousel/actions';

const mapStateToProps = (state) => ({
  carousel: state.carousel,
  header: state.header
});

const mapDispatchToProps = (dispatch) => {
  return {
    addCategories: cats => {
      dispatch(addCategories(cats))
    },
    toggleHeader: state => {
      dispatch(toggleHeader(state))
    },
    updateMousePosition: (x, y) => {
      dispatch(updateMousePosition(x, y))
    },
    updateNavColor: color => {
      dispatch(updateNavColor(color))
    }
  }
};

const HomeContainer = connect(mapStateToProps, mapDispatchToProps)(Home);

export default HomeContainer;