import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './carousel-item.css';
import CarouselItemNav from '../carousel-item-nav/CarouselItemNav';
import { transparentize } from 'polished';

class CarouselItem extends React.PureComponent {
  element = React.createRef();

  openDetails = () => {
    const { item, onItemClick } = this.props;
    const itemPosition = this.element.current.getBoundingClientRect();

    onItemClick(itemPosition, item);
  };

  render() {
    const { category, id, isFirst, isLast, item, onNavigate } = this.props;
    const { activeItem, color: c, title } = category;
    const isActive = id === activeItem;
    const color = transparentize(0.2, c);
    const style = classNames({
      carousel__item: true,
      'carousel__item--active': isActive
    });

    return (
      <div
        className={style}
        onClick={this.openDetails}
        ref={this.element}
        style={{ backgroundImage: `url(../projects/${item.cover})` }}
      >
        <CarouselItemNav
          onNavigate={onNavigate}
          isFirst={isFirst}
          isLast={isLast}
        />

        <h2 className="carousel__item-category" style={{ color }}>
          {title}
        </h2>
        <h1 className="carousel__item-title">{item.title}</h1>
        <h3 className="carousel__item-subtitle">
          {item.client && (
            <span style={{ background: color }}>{item.client}</span>
          )}
        </h3>
        <span className="carousel__item-description">
          {item.description && (
            <span style={{ background: color }}>{item.description}</span>
          )}
        </span>
      </div>
    );
  }
}

CarouselItem.propTypes = {
  category: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired,
  isFirst: PropTypes.bool.isRequired,
  isLast: PropTypes.bool.isRequired,
  item: PropTypes.shape({
    client: PropTypes.string,
    cover: PropTypes.string.isRequired,
    description: PropTypes.string,
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired
  }).isRequired,
  onNavigate: PropTypes.func.isRequired,
  onItemClick: PropTypes.func.isRequired
};

export default CarouselItem;
