import React from 'react';

const Logo = ({classname, color, onClick = false}) => {
  const clickHandler = () => {
    if (!onClick) {
      return;
    }

    onClick();
  }

  const styles = {
    fill: 'none',
    stroke: color,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeWidth: '161.7px'
  };

  return (
    <span className={classname} onClick={clickHandler}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4251.14 2254.6" style={{height: '100%'}}>
        <title>Zuketa logo</title>
        <polyline style={styles} points="80.85 80.85 670.87 80.85 80.85 970.76 670.87 970.76"/>
        <path style={styles} d="M1091.45,229.18V785.36c0,184.3,149.41,333.71,333.71,333.71s333.71-149.41,333.71-333.71V229.18" transform="translate(-144.15 -148.34)"/>
        <polyline style={styles} points="670.87 2172.28 82.32 2172.28 82.32 1653.16 82.32 1282.37 670.87 1282.37"/>
        <line style={styles} x1="527.26" y1="1727.33" x2="80.85" y2="1727.33"/>
        <polyline style={styles} points="4168.98 2173.02 3731.82 1283.1 3294.66 2173.02"/>
        <line style={styles} x1="3412.48" y1="1933.17" x2="4050.03" y2="1933.17"/>
        <polyline style={styles} points="1959.68 83.49 1959.68 528.45 1959.68 973.41"/>
        <line style={styles} x1="4170.3" y1="83.49" x2="1959.68" y2="645.54"/>
        <line style={styles} x1="2732.55" y1="449.04" x2="4170.3" y2="973.41"/>
        <line style={styles} x1="947.3" y1="1283.84" x2="3294.66" y2="1283.84"/>
        <line style={styles} x1="2120.98" y1="1283.84" x2="2120.98" y2="2173.75"/>
      </svg>
    </span>
  )
}

export default Logo;