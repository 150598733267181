import axios from 'axios';

const api = axios.create({
  baseURL: 'https://zuketa.com/projects/data/'
});

export const getProjectsList = () => {
  return api.get('list.json');
};

export const getProject = slug => {
  return api.get(`${slug}.json`);
};

export const getModernitska = () => {
  return api.get(`modernitska.json`);
};
