import React from 'react';
import PropTypes from 'prop-types';

const Record = ({classname}) => {
  const style = {
    fill: 'none',
    stroke: '#ffde03',
    strokeWidth: '2px'
  };

  const svg = {
    strokeDasharray: 94,
    strokeDashoffset: 94,
  };

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" className={classname} style={svg}>
      <circle style={style} cx="16" cy="16" r="15"/>
    </svg>
  )
}

Record.propTypes = {
  classname: PropTypes.string.isRequired
};

export default Record;
