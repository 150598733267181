import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Navigation from './Navigation';
import { updateNavColor } from './actions';

const mapStateToProps = (state) => ({
  nav: state.nav
});

const mapDispatchToProps = (dispatch) => {
  return {
    updateNavColor: color => {
      dispatch(updateNavColor(color))
    }
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Navigation);