const defaultState = {
  active: false,
  direction: ''
};

export const arrow = (state = defaultState, action) => {
  switch(action.type) {
    case 'TOGGLE_ARROW':
      return Object.assign({}, state, {
        active: action.active
      });
    case 'ROTATE_ARROW': 
      return Object.assign({}, state, {
        direction: action.direction
      });
    default:
      return state;
  }
};