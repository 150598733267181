const defaultState = {
  activeRow: 0,
  categories: []
};

export const carousel = (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_ACTIVE_ROW':
      return Object.assign({}, state, {
        activeRow: action.activeRow
      });
    case 'SET_ACTIVE_ITEM':
      return Object.assign({}, state, {
        categories: state.categories.map((category) => {
          if (category.id === action.categoryId) {
            return {
              ...category,
              activeItem: action.itemId,
            }
          }
          return category;
        })
      });
    case 'ADD_CATEGORIES':
      return Object.assign({}, state, {
        categories: action.categories
      });
    default:
      return state;
  }
};