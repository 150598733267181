import React from 'react';
import PropTypes from 'prop-types';

const Pause = ({ classname }) => {
  const style = {
    fill: 'none',
    stroke: '#e00025',
    strokeWidth: '2px',
    fillRule: 'evenodd'
  };

  const svg = {
    strokeDasharray: 60,
    strokeDashoffset: 60,
  };

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="32" viewBox="0 0 16 32" className={classname} style={svg}>
      <path style={style} d="M2192,3059v30m-14,0v-30" transform="translate(-2177 -3058)" />
    </svg>
  )
}

Pause.propTypes = {
  classname: PropTypes.string
};

export default Pause;