import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './video-player.css';
import PlayVideo from '../play-video/PlayVideo';
import VideoControls from '../video-controls/VideoControls';

class VideoPlayer extends React.Component {
  state = { duration: 0, isPlaying: false, progress: 0, showVideo: false };
  video = React.createRef();

  componentWillUnmount() {
    if (this.video.current) {
      this.video.current.pause();
      this.video.current.currentTime = 0;
    }
  }

  toggleCover = () => {
    this.setState({
      isPlaying: !this.state.isPlaying,
      showVideo: !this.state.showVideo
    });

    if (!this.state.showVideo) {
      this.video.current.play();
    }
  };

  togglePlay = (e) => {
    e.stopPropagation();
    const video = this.video.current;

    if (video.paused) {
      video.play();
    } else {
      video.pause();
    }

    this.setState({ isPlaying: !video.paused });
  }

  onSeek = (time) => {
    this.video.current.currentTime = time;
  }


  // rewinds video on end and brings cover back
  onEnded = () => {
    this.toggleCover();
    this.video.current.currentTime = 0;
  }

  onLoaded = () => {
    const video = this.video.current;

    this.setState({ duration: video.duration })
  }

  onFullscreen = (e) => {
    e.stopPropagation();

    const video = this.video.current;
    if (video.webkitEnterFullscreen) video.webkitEnterFullscreen();
    else if (video.requestFullscreen) video.requestFullscreen();
    else if (video.mozRequestFullScreen) video.mozRequestFullScreen();
    else if (video.msRequestFullscreen) video.msRequestFullscreen();
  }

  onTimeUpdate = () => {
    const video = this.video.current;
    const progress = video.currentTime;

    this.setState({ progress });
  }

  render() {
    const { color, cover, src } = this.props;
    const { duration, isPlaying, progress, showVideo } = this.state;

    const coverStyle = classNames('video-player__cover', {
      'video-player__cover--inactive': showVideo
    });

    return (
      <figure className="video-player">
        <div className={coverStyle} style={{ backgroundImage: `url(../projects/${cover}` }}>
          {src && (
            <span className="video-player__play">
              <PlayVideo onClick={this.toggleCover} visible={!showVideo} />
            </span>
          )}
        </div>
        {src && (
          <div className="video-player__video" onClick={this.togglePlay}>
            <video preload="metadata" playsInline className="video-player__video" ref={this.video}
              onEnded={this.onEnded} onLoadedMetadata={this.onLoaded} onTimeUpdate={this.onTimeUpdate}>
              <source src={src} type="video/mp4" />
            </video>

            <VideoControls
              active={showVideo}
              color={color} duration={duration}
              progress={progress} isPlaying={isPlaying}
              onFullscreen={this.onFullscreen} onSeek={this.onSeek}
            />
          </div>
        )}
      </figure>
    );
  }
}

VideoPlayer.propTypes = {
  color: PropTypes.string,
  cover: PropTypes.string.isRequired,
  src: PropTypes.string,
};

export default VideoPlayer;
