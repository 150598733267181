import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './input.css';

const TextField = ({ name, type, placeholder, onChange, onFocus, value }) => (
  <input
    type={type}
    name={name}
    placeholder={placeholder}
    onChange={onChange}
    onFocus={onFocus}
    onBlur={onFocus}
    value={value}
  />
);

const TextArea = ({ name, type, placeholder, onChange, onFocus, value }) => (
  <textarea
    type={type}
    name={name}
    rows="5"
    placeholder={placeholder}
    onChange={onChange}
    onFocus={onFocus}
    onBlur={onFocus}
    value={value}
  ></textarea>
);

class Input extends React.PureComponent {
  state = { focus: false };

  onFocus = e => {
    this.setState({
      focus: !this.state.focus
    });
  }

  onChange = e => {
    const { value } = e.target;
    this.props.onUpdate(this.props.name, value);
  }

  render() {
    const { placeholder, type } = this.props;
    const labelActive = this.props.value.length > 0;
    const labelFocus = this.state.focus;

    const labelStyle = classNames({
      'input__label': true,
      'input__label--active': labelActive
    })

    const inputStyle = classNames({
      'input': true,
      'input__textarea': type === 'textarea',
      'input__focus': labelFocus
    });

    return (
      <span className={inputStyle}>
        {type === 'text' && (
          <TextField {...this.props} onChange={this.onChange} onFocus={this.onFocus} />
        )}
        {type === 'textarea' && (
          <TextArea {...this.props} onChange={this.onChange} onFocus={this.onFocus} />
        )}
        <label className={labelStyle}>{placeholder}</label>
      </span>
    )
  }
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string,
  value: PropTypes.string,
};

Input.defaultProps = {
  type: 'text'
};

export default Input;
