import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./play-video.css";

const PlayVideo = ({ onClick, visible }) => {
  const style = classNames({
    "play-video": true,
    "play-video--inactive": !visible,
  });

  return (
    <span className={style} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 165.1 143.2"
        style={{ fillOpacity: 0 }}
      >
        <polyline
          className="play-video__icon"
          points="6,83.9 6,6 159.1,71.6 6,137.2 "
        />
      </svg>
    </span>
  );
};

PlayVideo.propTypes = {
  onClick: PropTypes.func
};

export default PlayVideo;
