import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import './modernitska.css';
import ContactForm from '../contact-form/ContactForm';
import Card from '../about/card/Card';
import { getModernitska } from '../../utils/data';

class Modernitska extends Component {
	state = { activeCard: null, locations: [] };

	onCardClick = (id) => {
		this.setState({ activeCard: id });
	};

	componentDidMount() {
		getModernitska().then((response) => {
			this.setState({ locations: response.data.locations });
		});
	}

	render() {
		const { cover } = this.props;
		const { activeCard, locations } = this.state;

		return (
			<article className="modernitska">
				{cover && (
					<header
						className="modernitska__header"
						style={{ backgroundImage: `url(../projects/${cover})` }}
					/>
				)}
				<div className="modernitska__content">
					<section className="modernitska__details">
						<h2 className="modernitska__subtitle">Modernitska</h2>
						<h1 className="modernitska__title">
							Exceptional, royalty-free stock videos
						</h1>

						<div className="modernitska__description">
							<p>
								On our travels around the world we always encounter great
								buildings and locations.
							</p>
							<p>
								We have been making films of these locations as a way to archive
								and catalogue them for ourselves.
							</p>
							<p>
								Now we are offering them to other directors, videographers or
								architecture enthusiasts as royalty-free stock videos for use in
								commercial or non-commercial purposes.
							</p>
							<p>
								They can be purchased as a set or individually. Please contact
								us for details.{' '}
							</p>
						</div>

						<div className="cards">
							{locations.map((location) => (
								<Card
									active={activeCard}
									key={location.id}
									onClick={this.onCardClick}
									data={location}
								/>
							))}
						</div>
					</section>
				</div>

				<ContactForm headline="Get in touch for more details" />
			</article>
		);
	}
}

Modernitska.propTypes = {
	cover: PropTypes.string,
};

export default withRouter(Modernitska);
