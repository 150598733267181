import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { DIR_LEFT, DIR_RIGHT } from '../../../../utils/constants';
import CarouselArrow from '../../carousel-arrow/CarouselArrow';

class CarouselItemNav extends React.PureComponent {
  render() {
    const { isFirst, isLast, onNavigate, color } = this.props;

    return (
      <div className="carousel__item-nav">
        {!isFirst && (
          <span
            className="carousel__item-arrow left"
            onClick={e => onNavigate(e, DIR_LEFT)}
          >
            <CarouselArrow direction={DIR_LEFT} />
          </span>
        )}

        {!isLast && (
          <span
            className="carousel__item-arrow right"
            onClick={e => onNavigate(e, DIR_RIGHT)}
          >
            <CarouselArrow direction={DIR_RIGHT} />
          </span>
        )}
      </div>
    );
  }
}

CarouselItemNav.propTypes = {
  isFirst: PropTypes.bool.isRequired,
  isLast: PropTypes.bool.isRequired,
  onNavigate: PropTypes.func.isRequired
};

export default CarouselItemNav;
