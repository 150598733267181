import React from 'react';
import OnVisible, { setDefaultProps } from 'react-on-visible';
import PropTypes from 'prop-types';

import './project-details.css';

setDefaultProps({
  className: 'project-details__copy',
  visibleClassName: 'visible'
});

const ProjectDetails = ({ project }) => {
  if (!project) {
    return null;
  }

  return (
    <section className="project-details">
      <div className="project-details__inner">
        <div className="project-details__desktop">
          <OnVisible>
            <h2
              className="project-details__category"
              style={{ color: project.categoryColor }}
            >
              {project.categoryName}
            </h2>
          </OnVisible>

          <OnVisible>
            <h1 className="project-details__title">{project.title}</h1>
          </OnVisible>
        </div>

        <div className="project-details__mobile">
          <h2
            className="project-details__category"
            style={{ color: project.categoryColor }}
          >
            {project.categoryName}
          </h2>
          <h1 className="project-details__title">{project.title}</h1>
        </div>

        {project.client && (
          <OnVisible>
            <h3 className="project-details__subtitle">{project.client}</h3>
          </OnVisible>
        )}

        <OnVisible>
          <article className="project-content">
            <h4 className="project-content__title">
              {project.video && project.video.title}
            </h4>
            <p className="project-content__description">
              {project.description}
            </p>
          </article>
        </OnVisible>

        {project.more && project.more.length && (
          <>
            <h4 style={{ marginBottom: '5px' }}>More</h4>
            <>
              {project.more.map(more => (
                <p>
                  {more.title}:{' '}
                  <a style={{ color: project.categoryColor }} href={more.link}>
                    {more.link}
                  </a>
                </p>
              ))}
            </>
          </>
        )}

        {project.blocks &&
          project.blocks.map((block, i) => (
            <div className="project-details__block" key={i}>
              <img src={`../projects/${block.image}`} alt={block.name} />
            </div>
          ))}
      </div>
    </section>
  );
};

ProjectDetails.propTypes = {
  project: PropTypes.object.isRequired
};

export default ProjectDetails;
