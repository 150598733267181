import React from 'react';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import './home-header.css';
import Logo from '../logo/Logo';
import headerVideo from './header.webm';
import headerVideoMp4 from './header.mp4';
import ScrollDown from '../scroll-down/ScrollDown';

class HomeHeader extends React.Component {
  video = React.createRef();

  componentDidMount() {
    const video = this.video.current;
    if (!this.props.header.scrolled) {
      video.muted = true;
      video.play();
    } else {
      video.pause();
    }
  }

  componentDidUpdate() {
    if (this.props.header.scrolled) {
      this.video.current.pause();
    } else {
      this.video.current.play();
    }
  }

  //ensure smooth scroll
  onWheel = debounce((e) => {
    e.stopPropagation();
    e.preventDefault();
    //if header visible and scroll down without pinch
    if (!this.props.header.scrolled && e.deltaY > 0 && !e.ctrlKey) {
      this.scrollDown();
    }
  }, 400, { leading: true, trailing: false });

  scrollDown = () => {
    this.props.toggleHeader(true);
  }

  render() {
    const { header } = this.props

    const headerStyle = classNames('home__header', {
      'home__header--scrolled': header.scrolled
    });

    const logoStyle = classNames('home__header-logo', {
      'home__header-logo--scrolled': header.scrolled
    });

    return (
      <header className={headerStyle} onWheel={this.onWheel} >
        <video ref={this.video} className="home__header-video" muted preload="auto" loop playsInline>
          <source src={headerVideoMp4} type="video/mp4" />
          <source src={headerVideo} type="video/webm" />
        </video>
        <Logo classname={logoStyle} color="white" />

        <h1 className="home__header-copy">Design & Architecture film production</h1>

        <ScrollDown onClick={this.scrollDown} />
      </header>
    )
  }
};

HomeHeader.propTypes = {
  header: PropTypes.shape({
    scrolled: PropTypes.bool.isRequired
  }).isRequired,
  toggleHeader: PropTypes.func.isRequired
}

export default withRouter(HomeHeader);