import React from 'react';
import PropTypes from 'prop-types';

const Stop = ({classname}) => {

  const style = {
    fill: 'none',
    stroke: '#488dc8',
    strokeWidth: '2px',
  };

  const svg = {
    strokeDasharray: 120,
    strokeDashoffset: 120,
  };
  
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={classname} width="32" height="32" viewBox="0 0 32 32" style={svg}>
      <rect style={style} x="1" y="1" width="30" height="30"/>
    </svg>
  );
};

Stop.propTypes = {
  classname: PropTypes.string.isRequired
};

export default Stop;