import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router';

import Project from './Project';
import { updateNavColor } from '../navigation/actions';

const mapDispatchToProps = (dispatch) => {
  return {
    updateNavColor: color => {
      dispatch(updateNavColor(color))
    }
  }
};

const ProjectContainer = compose(
  withRouter,
  connect(null, mapDispatchToProps)
)(Project);

export default ProjectContainer;