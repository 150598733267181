const defaultState = {
  scrolled: false
};

export const header = (state = defaultState, action) => {
  switch(action.type) {
    case 'TOGGLE_HEADER':
      return Object.assign({}, state, {
        scrolled: action.scrolled
      });
    default: 
      return state;
  }
}