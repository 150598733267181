import React, { Component } from 'react';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';

import './home.css';

import CarouselContainer from '../carousel/CarouselContainer';
import { getProjectsList } from '../../utils/data';
import HomeHeaderContainer from '../home-header/HomeHeaderContainer';
import { APP_GREY } from '../../utils/constants';

class Home extends Component {
  state = { categories: [] };

  componentDidMount() {
    this.props.updateNavColor(APP_GREY);

    if (!this.props.carousel.categories.length) {
      this.loadProjects();
    }
  }

  loadProjects = () => {
    getProjectsList()
      .then(response => {
        const { categories } = response.data;
        // sets first item as default
        categories.forEach((cat) => {
          cat.activeItem = 0;
        });

        this.props.addCategories(categories);
      })
      .catch(err => console.error(err));
  }

  onMouseMove = (e) => {
    const { clientX, clientY } = e;
    this.props.updateMousePosition(clientX, clientY);
  }

  onWheel = debounce((e) => {
    e.stopPropagation();
    const { scrolled } = this.props.header;

    if (scrolled && e.deltaY < 0 && !e.ctrlKey) {
      this.props.toggleHeader(false);
    }
  }, 400, { leading: true, trailing: false });

  onItemClick = (itemPosition, item) => {
    const { history, location } = this.props;
    const { top, right, bottom, left, width, height } = itemPosition;

    history.push({
      pathname: `/${item.slug}`,
      state: {
        to: 'modal',
        from: location,
        meta: {
          item,
          position: { top, right, bottom, left, width, height },
        },
      },
    });
  }

  render() {
    const { header } = this.props;

    const projectsStyle = classNames({
      'home__projects': true,
      'home__projects--active': header.scrolled
    });

    return (
      <main className="home" onMouseMove={this.onMouseMove}>
        <HomeHeaderContainer />

        <section className={projectsStyle} onWheel={this.onWheel}>
          <CarouselContainer onItemClick={this.onItemClick} />
        </section>
      </main>
    );
  }
}

Home.propTypes = {
  carousel: PropTypes.shape({
    activeRow: PropTypes.number.isRequired,
    categories: PropTypes.array.isRequired,
  }),
  header: PropTypes.shape({
    scrolled: PropTypes.bool.isRequired
  }).isRequired,
  toggleHeader: PropTypes.func.isRequired
}

export default Home;