const defaultState = {
  x: 0,
  y: 0
};

export const mouse = (state = defaultState, action) => {
  switch(action.type) {
    case 'UPDATE_MOUSE_POSITION': 
      return Object.assign({}, state, {
        x: action.x,
        y: action.y,
      });
    default:
      return state;
  }
}