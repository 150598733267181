import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, combineReducers } from 'redux';
import 'normalize.css';

import './index.css';
import App from './App';
import unregister from './registerServiceWorker';

import { arrow } from './components/carousel/carousel-arrow/reducers';
import { carousel } from './components/carousel/reducers';
import { header } from './components/home-header/reducers';
import { mouse } from './components/home/reducers';
import { nav } from './components/navigation/reducers';

const store = createStore(
	combineReducers({ arrow, carousel, header, mouse, nav }),
);

ReactDOM.render(
	<Provider store={store}>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</Provider>,
	document.getElementById('root'),
);

unregister();
// const unsubscribe = store.subscribe(() => console.log(store.getState()));

// unsubscribe();
