import React from 'react';
import PropTypes from 'prop-types';

import './carousel-nav.css';
import CarouselNavIcon from './carousel-nav-icon/CarouselNavIcon';

const CarouselNav = ({activeRow, items, onClick}) => {
  const isActive = (i) => i === activeRow;

  return (
    <nav className="carousel__nav">
      {items.map((item, i) => {

        return (
          <React.Fragment key={i}>
            <span className="carousel__nav-dot-container" onClick={() => onClick(i)}>
              <CarouselNavIcon active={isActive(i)} color={item.color} icon={item.icon}/>
            </span>

            <span className="carousel__nav-separator"></span>
          </React.Fragment>
      )})}
    </nav>
  )
}

CarouselNav.propTypes = {
  // activeRow: PropTypes.number.isRequired,
  items: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired
};

export default CarouselNav;