import React from 'react';

import './video-header.css';
import VideoPlayer from '../video-player/VideoPlayer';

const VideoHeader = ({ color, cover, src }) => {
  if (!cover) {
    return null;
  }

  return (
    <header className="video-header">
      <VideoPlayer color={color} cover={cover} src={src} />
    </header>
  );
};

export default VideoHeader;
