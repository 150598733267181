import { connect } from 'react-redux';

import CarouselArrow from './CarouselArrow';

const mapStateToProps = state => ({
  arrow: state.arrow,
  mouse: state.mouse
});

const CarouselArrowContainer = connect(mapStateToProps)(CarouselArrow);

export default CarouselArrowContainer;