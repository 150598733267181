import { APP_GREY } from '../../utils/constants';

const defaultState = {
  color: APP_GREY
};

export const nav = (state = defaultState, action) => {
  switch(action.type) {
    case 'UPDATE_NAV_COLOR':
      return Object.assign({}, state, {
        color: action.color
      });
    default:
      return state;
  }
}