import React from 'react';
import PropTypes from 'prop-types';

import './scroll-down.css';
import scroll from './scroll-down.svg';

const ScrollDown = ({onClick}) => (
  <span className="scroll-down" onClick={onClick}>
    <img src={scroll} className="scroll-down__icon" alt="Scroll down"/>
  </span>
);

ScrollDown.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default ScrollDown;