import React from 'react';

import './about.css';
import ContactForm from '../contact-form/ContactForm';

const About = (props) => {
	return (
		<section className="about">
			<div className="about__wrapper">
				<div className="about__content">
					<div className="about__content-header" />
				</div>

				<div className="about__content">
					<div className="about__content-heading">
						<h1 className="about__content-title">Zuketa</h1>
						<h2 className="about__content-subtitle">
							Design & Architecture film company
						</h2>
					</div>

					<div className="about__content-description">
						<h3 className="about__content-subtitle">Who we are</h3>
						<p>
							Zuketa specialises in creative documentary films for exhibitions
							and online publications. Working primarily for cultural
							institutions across the fields of design and architecture, we
							produce films that capture how people interact with design in
							numerous ways; as maker and producer as well as audience and user.
							We are interested in showing how things are thought up and made
							into reality, and how we eventually recognise their value through
							experience.
						</p>
						<p>
							The nature of the work means we are always on location, from
							Ferrari in Italy and Artek in Finland to marble quarries in
							Carrara and melon farms in Utah, not to mention many architectural
							sites around the world.
						</p>
						<p>
							Our films have been presented at various film festivals including
							Real to Reel in London and the Film and Architecture Festival in
							Prague.
						</p>

						<h3 className="about__content-subtitle">What we do</h3>
						<p>
							We take on different types of project, from a single film for an
							online publication to a complete set of films for an exhibition.
							From a brief given by a client, we develop concept and storyboard
							that we turn into a carefully put together visual narrative. We
							work with appointed AV technicians to makes sure the installation
							is perfect in the space, and have experience in working with
							complex multiple screen presentations as well as unusual formats.
							In addition to newly commissioned films we also edit archival
							footage for exhibitions.
						</p>

						<p>
							Recent clients include: the Design Museum (London), the Victoria &
							Albert Museum, the British Council, Design Society (Shenzhen) and
							the Seoul Biennale of Architecture and Urbanism, Craft Council and
							Bompas & Parr.
						</p>

						<h3 className="about__content-subtitle">
							The people behind Zuketa
						</h3>
						<p>
							Alice Doušová, (Masters) is a director and producer based in East
							London. Before traveling to the UK, Alice started a non-profit
							video channel called Artycok.tv in collaboration with the Art
							University of Prague, creating short reportages about artists. She
							studied at the Chelsea College of Art and shortly after became the
							in-house film maker and editor at the Design Museum London.
						</p>
						<p>
							In addition to filming, Alice has been conceptualising, writing
							and directing films specifically made for exhibitions, mini-docs
							and short films, overseeing the production of Zuketa’s output. Her
							particular predilection is working with the tripod, which she uses
							to frame each shot as if it were a moving photograph. Alice’s
							interests include urbanism and architecture, and she finds herself
							at home in any big city.
						</p>

						<p>
							Joe Almond is a videographer and photographer. At Zuketa, his work
							complements Alice’s tripod work with his seamless motion shots
							made using his Steadicam. Having to work with dancers, motor
							cyclists and set designers, his camera work was particularly
							important during the production of London Made, our multiple
							screen installation for the Seoul Biennale of Architecture and
							Urbanism 2017. Joe is a keen skateboarder and mountain climber:
							hobbies which feed into his interest in capturing motion.
						</p>
						<p>
							Lucia Sceranková is an art photographer and film editor. She
							trained at the Department of Intermedia and Multimedia at the
							Academy of Fine Arts and Design in Bratislava, and has worked with
							Zuketa on film editing for the British Council and the Design
							Museum. Lucie has recently completed the Avid Media Composer
							course, which is a professional film and television editing
							software. The technical precision and attention to detail with
							which Lucia composes her photographic work are also present in her
							editing of newly commissioned footage that complements archival
							film footage on occasions.
						</p>
					</div>
				</div>
			</div>

			<ContactForm />
		</section>
	);
};
export default About;
