import React from 'react';

const Cross = ({ color }) => {
  const style = {
    stroke: color || '#686868',
    fill: color || '#686868'
  };

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60.07 60.07" style={{ height: '100%', width: '100%' }}>
      <title>Close</title>
      <path style={style} d="M20.2,11.75a6,6,0,0,0-8.49,8.49L59.8,68.32a6,6,0,1,0,8.49-8.49Z" transform="translate(-9.97 -10)" />
      <path style={style} d="M68.28,20.24a6,6,0,1,0-8.49-8.49L11.72,59.84a6,6,0,1,0,8.49,8.49Z" transform="translate(-9.97 -10)" />
    </svg>
  )
}

export default Cross;