import Carousel from './Carousel';
import { connect } from 'react-redux';

import { setActiveRow } from './actions';

const mapStateToProps = (state, ownProps) => ({
  carousel: state.carousel,
  header: state.header,
  ...ownProps
});

const mapDispatchToProps = dispatch => {
  return {
    setActiveRow: id => {
      dispatch(setActiveRow(id));
    }
  }
}

const CarouselContainer = connect(mapStateToProps, mapDispatchToProps)(Carousel);

export default CarouselContainer;