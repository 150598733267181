import { connect } from 'react-redux';
import HomeHeader from './HomeHeader';

import { toggleHeader } from './actions';

const mapStateToProps = (state) => ({
  header: state.header
});

const mapDispatchToProps = (dispatch) => {
  return {
    toggleHeader: state => {
      dispatch(toggleHeader(state));
    }
  }
}

const HomeHeaderContainer = connect(mapStateToProps, mapDispatchToProps)(HomeHeader);

export default HomeHeaderContainer;