export const setActiveRow = id => ({
  type: 'SET_ACTIVE_ROW',
  activeRow: id
});

export const setActiveItem = (itemId, categoryId) => ({
  type: 'SET_ACTIVE_ITEM',
  itemId,
  categoryId,
});

export const addCategories = cats => ({
  type: 'ADD_CATEGORIES',
  categories: cats
});