import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './contact-form.css';
import Input from '../input/Input';

class ContactForm extends React.Component {
  state = { name: '', email: '', message: '', sent: false, valid: false };

  checkForm = () => {
    const { name, email, message } = this.state;

    return name.replace(/^\s+|\s+$/g, '').length !== 0
      && email.replace(/^\s+|\s+$/g, '').length !== 0
      && message.replace(/^\s+|\s+$/g, '').length !== 0;
  }

  onSubmit = (e) => {
    e.preventDefault();

    if (this.state.valid) {
      this.setState({ name: '', message: '', email: '', sent: true, valid: false });

    }
  }

  onUpdate = (name, value) => {
    this.setState({ [name]: value });

    if (this.checkForm()) {
      this.setState({ valid: true })
    }
  }

  render() {
    const { headline } = this.props;
    const btnStyle = classNames('contact-form__btn', {
      'contact-form__btn--disabled': !this.state.valid,
      'contact-form__btn--inactive': this.state.sent,
    });
    const feedbackStyle = classNames('contact-form__feedback', {
      'contact-form__feedback--active': this.state.sent,
    });

    return (
      <div className="contact-form" >
        <h1 className="contact-form__heading">
          {headline}
        </h1>
        <form onSubmit={this.onSubmit}>
          <span className="contact-form__field">
            <Input type="text" placeholder="Your name" name="name" onUpdate={this.onUpdate} value={this.state.name} />
          </span>
          <span className="contact-form__field">
            <Input type="text" placeholder="Your email" name="email" onUpdate={this.onUpdate} value={this.state.email} />
          </span>

          <span className="contact-form__field--large">
            <Input type="textarea" placeholder="Your message" name="message" onUpdate={this.onUpdate} value={this.state.message} />
          </span>

          <div className="contact-form__cta">
            <span className={feedbackStyle}>Thank you, we will get back to you as soon as we can.</span>
            <button className={btnStyle} disabled={!this.state.valid}>Send message</button>
          </div>
        </form>
      </div>
    );
  }
};

ContactForm.propTypes = {
  headline: PropTypes.string
};

ContactForm.defaultProps = {
  headline: `Let's work together... Drop us a line !`
};

export default ContactForm;
