import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Pause from './pause/Pause';
import Play from './play/Play';
import Record from './record/Record';
import Stop from './stop/Stop';

import './carousel-nav-icon.css';

class CarouselNavIcon extends React.PureComponent {
  render () {
    const {active, color, icon} = this.props;

    const icons = {
      pause: Pause,
      play: Play,
      record: Record,
      stop: Stop
    };

    const Icon = icons[icon];

    if (!Icon) {
      return null;
    }

    const dotStyle = classNames({
      'carousel__nav-dot': true,
      'carousel__nav-dot-active': !active,
    })

    const iconStyle = classNames({
      'carousel__nav-icon': true,
      'carousel__nav-icon-active': active,
    })

    return (
      <React.Fragment>
        <span className={dotStyle} style={{backgroundColor: color}}></span>
        <Icon classname={iconStyle}/>
      </React.Fragment>
    )

  }
}

CarouselNavIcon.propTypes = {
  icon: PropTypes.string.isRequired
};

export default CarouselNavIcon;