import React from 'react';
import { withRouter, Route, Switch } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import './modal-project.css';
import ProjectContainer from '../project/ProjectContainer';
import Modernitska from '../modernitska/Modernitska';

class ModalProject extends React.PureComponent {
  render() {
    const { location, isModal } = this.props;
    const meta = this.props.location.state && this.props.location.state.meta;
    const item = meta ? meta.item : null;
    const position = meta ? meta.position : null;

    return (
      <TransitionGroup component={null}>
        <CSSTransition
          classNames="modal"
          appear
          in={isModal}
          key={location.key}
          mountOnEnter
          timeout={450}
        >
          <div className="modal-project" style={position}>
            {isModal ? (
              <Switch location={location}>
                <Route
                  path="/modernitska"
                  render={() => <Modernitska {...item} />}
                />
                <Route
                  path="/:slug"
                  render={() => <ProjectContainer {...item} />}
                />
              </Switch>
            ) : null}
          </div>
        </CSSTransition>
      </TransitionGroup>
    );
  }
}

export default withRouter(ModalProject);
