import React from 'react';
import PropTypes from 'prop-types';

const Play = ({ classname }) => {
  const style = {
    fill: 'none',
    stroke: '#0dceb0',
    strokeWidth: '2px',
    fillRule: 'evenodd',
  };

  const svg = {
    strokeDasharray: 98,
    strokeDashoffset: 98,
  };

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" className={classname} style={svg}>
      <path style={style} d="M2170,1693l29.99-15L2170,1663v30Z" transform="translate(-2169 -1662)" />
    </svg>
  )
}

Play.propTypes = {
  classname: PropTypes.string
};


export default Play;