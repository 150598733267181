import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './card.css';

class Card extends React.Component {
  state = { active: false };
  video = React.createRef();

  onClick = () => {
    if (!this.state.active) {
      this.setState({ active: true });
      this.video.current.currentTime = 0;
      setTimeout(() => {
        this.video.current.play();
      }, 600);
      this.props.onClick(this.props.data.id);
    }
  }

  onBlur = () => {
    this.setState({ active: false });
    this.props.onClick(null);
    this.video.current.pause();
  }

  render() {
    const { active, data } = this.props;
    const isInactive = (active !== data.id && active !== null);
    const cardStyle = classNames('card', {
      'card--active': this.state.active,
      'card--inactive': isInactive,
    });

    return (
      <div className={cardStyle} onClick={this.onClick} tabIndex="0" onBlur={this.onBlur}>
        <span className="card__image" style={{ backgroundImage: `url(../modernitska/${data.image})` }}></span>
        <video ref={this.video} className="card__video" loop playsInline>
          <source src={`../modernitska/${data.video}`} type="video/mp4" />
        </video>
        <div className="card__content">
          <span className="card__location">{data.location}</span>
          <h1 className="card__title">{data.title}</h1>
          <p className="card__description">{data.description}</p>
        </div>
      </div>
    );
  }
}

Card.propTypes = {
  data: PropTypes.object.isRequired,
  active: PropTypes.number,
  onClick: PropTypes.func,
};

export default Card;