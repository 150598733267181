import React from 'react';
import PropTypes from 'prop-types';

import './progress-bar.css';

class ProgressBar extends React.Component {
  bar = React.createRef();

  onClick = (e) => {
    e.stopPropagation();
    const bar = this.bar.current;
    const pos = (e.pageX - bar.offsetLeft) / bar.offsetWidth;

    const time = pos * this.props.duration;
    this.props.onSeek(time);
  }

  render() {
    const { color, duration, progress } = this.props;
    const width = ((progress / duration) * 100).toFixed(2);

    return (
      <div className="progress-bar" ref={this.bar} onClick={this.onClick}>
        <span className="progress-bar__progress" style={{ backgroundColor: color, width: `${width}%` }}></span>
      </div>
    );
  }
};

ProgressBar.propTypes = {
  color: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired,
  progress: PropTypes.number.isRequired,
};

export default ProgressBar;