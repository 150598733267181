import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './video-controls.css';
import ProgressBar from '../progress-bar/ProgressBar';
import Play from '../carousel/carousel-nav/carousel-nav-icon/play/Play';
import Pause from '../carousel/carousel-nav/carousel-nav-icon/pause/Pause';

const VideoControls = ({ active, color, duration, progress, isPlaying, onFullscreen, onSeek, togglePlay }) => {
  const playStyle = classNames('video-controls__icon', {
    '--inactive': isPlaying
  });

  const pauseStyle = classNames('video-controls__icon', {
    '--inactive': !isPlaying
  });

  if (!active) {
    return null;
  }

  return (
    <div className="video-controls">
      <span className="video-controls__icons" onClick={togglePlay}>
        <span className={playStyle}>
          <Play />
        </span>
        <span className={pauseStyle}>
          <Pause />
        </span>
      </span>
      <span className="video-controls__seekbar">
        <ProgressBar color={color} duration={duration} progress={progress} onSeek={onSeek} />
      </span>
      <span className="video-controls__icons">
        <span className="video-controls__icon" onClick={onFullscreen}>
          <img alt="Enter fullscreen" src="icons/resize.svg" />
        </span>
      </span>
    </div >
  );
};

VideoControls.propTypes = {

};

export default VideoControls;