import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import './navigation.css';
import Logo from '../logo/Logo';
import Cross from '../cross/Cross';

class Navigation extends React.PureComponent {
  onClose = () => {
    const { history, location, updateNavColor } = this.props;

    if (location.pathname !== '/') {
      updateNavColor(null);
      history.push({
        pathname: '/'
      });
    }
  };

  render() {
    const { location, nav } = this.props;

    const color = nav.color;
    const showCross = location.pathname === '/' ? false : true;

    return (
      <nav className="navigation">
        <Logo
          classname="navigation__logo"
          color={color}
          onClick={this.onClose}
        />

        <span className="navigation__items">
          <CSSTransition
            classNames="navigation__item"
            in={!showCross}
            mountOnEnter
            timeout={400}
          >
            <Link to="/about">
              <span className="navigation__item" style={{ color: color }}>
                About
              </span>
            </Link>
          </CSSTransition>

          <CSSTransition
            classNames="navigation__cross"
            in={showCross}
            mountOnEnter
            timeout={400}
          >
            <span className="navigation__cross" onClick={this.onClose}>
              <Cross color={color} />
            </span>
          </CSSTransition>
        </span>
      </nav>
    );
  }
}

Navigation.propTypes = {
  nav: PropTypes.shape({
    color: PropTypes.string
  })
};

export default Navigation;
