import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './project.css';
import { getProject } from '../../utils/data';
import VideoHeader from '../video-header/VideoHeader';
import ProjectDetails from '../project-details/ProjectDetails';
import { APP_GREY } from '../../utils/constants';

class Project extends Component {
  state = { project: {} };

  componentDidMount() {
    const { history, match } = this.props;

    getProject(match.params.slug)
      .then(response => {
        const project = response.data;
        const navColor = project.invertNav ? 'white' : APP_GREY;

        this.setState({ project });
        this.props.updateNavColor(navColor);
      })
      .catch(err => {
        console.error(err);
        history.push({
          pathname: `/`
        });
      });
  }

  render() {
    const { project } = this.state;
    const cover = this.props.cover || project.cover;

    return (
      <article className="project">
        <VideoHeader color={project.categoryColor} cover={cover} src={project.video && project.video.src} />
        <ProjectDetails project={project} />
      </article>
    );
  }
}

Project.propTypes = {
  cover: PropTypes.string,
  updateNavColor: PropTypes.func.isRequired
};

export default Project;
